import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import Header from '../../components/header'
import CharacterGrid from '../../components/charactergrid'
import InBrief from '../../components/inbrief'
import AdBanner from '../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull } from '../../components/imageelements'
import { SynopsisAct } from '../../components/synposisstyling'
import Bibliography from '../../components/bibliography.js'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { FunFactsHeader } from '../../components/funfactsstyling'
import { MainBodySection } from '../../components/generalstyling'
import VideoPlayer from '../../components/videoplayer'


const characters = [
  {name: 'Rodolfo', voice: 'Tenor', desc: 'A young poet.'},
  {name: 'Mimì', voice: 'Soprano', desc: 'A young seamstress. Extremely ill with Tuberculosis from the outset as becomes extremely apparent as the opera continues.'},
  {name: 'Marcello', voice: 'Baritone', desc: 'A young painter. In a turbulent relationship with Musetta.'},
  {name: 'Musetta', voice: 'Soprano', desc: 'A singer and general girl about town. Pays her way with wealthy friends and lovers but regularly in a couple with Marcello.'},
  {name: 'Schaunard', voice: 'Baritone', desc: 'A musician. One of four artists who live in the shared garret.'},
  {name: 'Colline', voice: 'Bass', desc: 'A philosopher. One of four artists who live in the shared garret.'},
  {name: 'Benoît', voice: 'Bass', desc: 'The artists’ landlord. A bit of a creep.'},
  {name: 'Alcindoro', voice: 'Bass', desc: 'An affluent older lover of Musetta. Often played by the same singer as Benoît.'},
  {name: 'Parpignol', voice: 'Tenor', desc: 'A toy seller. He has a sweet but brief moment in Act II with lots of children. Your mileage may vary.'},
 ];

const inbrief = {name: "La bohème", composer: "Giacomo Puccini", librettist: "Luigi Illica and Giuseppe Giacosa", language: "Italian", date: "February 1st, 1896", acts: "Four", musiclength: "Two hours"};

const bibliography = [
  {name: 'Opera Classics, "La bohème"', writer: 'Burton D. Fisher', url: 'https://amzn.to/2ZCdVok'},
  {name: 'Scenes from the Life of Bohemia: Scènes De La Vie De Bohême', writer: 'Henri Murger', url: 'https://amzn.to/3mBca3Z'},
  {name: 'Puccini Without Excuses: A Refreshing Reassessment of the World\'s Most Popular Composer', writer: 'William Berger', url: 'https://amzn.to/3EFHULy'},   
  {name: 'Giacomo Puccini and His World', writer: 'Arman Schwartz & Emanuele Senici', url: 'https://amzn.to/3jWBrDN'},   
];

// markup
const BohemePage = () => {
  return (
    <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>La bohème| The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operas/boheme" />
          <meta name="description" content="A guide to Puccini's cornerstone of the operatic repertory, La bohème. Including Synopsis, Music & Arias, Fun Facts, Running Time and much more!" />
        </Helmet>
        <Hero title="La bohème" subtitle="An Opera by Giacomo Puccini"/>
        <MainBodySection>

          <ImageRight maxwidth="240px">
            <StaticImage src="../../images/operas/boheme/La_Boheme_poster_by_Hohenstein.jpg" alt="Adolfo Hohenstein's 1896 poster for La bohème"/>
            <figcaption>Adolfo Hohenstein's 1896 poster for La bohème</figcaption>
          </ImageRight>

          <p>La bohème is a warhorse of the operatic repertory, one of the most frequently performed around the world, and for excellent reason. It is a triumphant blend of grim reality and soaring romanticism. The narrative, set around the late 1830s in Paris, has aged remarkably well with a youthful universality that has endeared it to the opera-going public more or less continuously since its 1896 premiere.</p>

          <p>Bohème was Giacomo Puccini’s fourth opera and is generally considered to be his first fully mature work. It offers all the hallmarks of his canon, soaring tonal melodies in lush orchestrations combined with a dissonant undertow, a hint of darkness never far away. You’ll come to recognise his use of small musical motifs that though not highly structural, cunningly evoke characters and themes throughout the work. It also contains some incredible non-narrative scene-setting music, the openings of Acts II and III in particular masterclasses in producing atmosphere through sound.</p>

          <p>It’s an easy opera to love, packed with those big tunes and an outstanding balance of the intimate and the spectacular (the curtain up on Act II is regularly an applause getter in many productions). We hope you come to adore it as much as we do!</p>
          
          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <Header title="In Brief"/>

          <InBrief content={inbrief}/>

          </MainBodySection>

  
          <div id="ezoic-pub-ad-placeholder-103"> </div>


            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Characters"/>

            <CharacterGrid rows={characters}/>

            <Header title="Synopsis"/>

            <SynopsisAct>Act I - Running Time: 40 mins</SynopsisAct>

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/act_1_arizona.jpeg" alt="The Act I Garret"/>
              <figcaption>The Act I Garret &copy; Tim Trumble/Arizona Opera</figcaption>
            </ImageFull>

            <p>The curtain rises on a dismal garret in Paris on Christmas Eve, the late 1830s. Rodolfo and Marcello, two of the four artists living together in this cramped space, are desperate to keep warm going as far as to burn Rodolfo’s latest play. Their two other housemates Colline and Schaunard return. Colline is despondent and cold, but Schaunard is elated, arriving with food & wine, firewood and money, having earned a well-paying job with some eccentric gentleman.</p>

            <p>They raucously begin to set up dinner from this good fortune, but Schaunard insists that they dine out on him tonight! These celebrations are quickly interrupted by Benoît, their landlord, who arrives demanding the rent. The group immediately offers him wine, and having gotten him drunk on the booze and flattery, Benoît regales them with tales of his romantic pursuits. However, when he reveals he is married, they comically turn on him, throwing him out (without the rent!) in faux moral indignation.</p>

            <p>All but Rodolfo go out; he requests a few moments to finish writing an article and says he will join them later. Not long after they leave, there is a knock at the door. It is Mimì, who lives in another room in the building. Her candle has blown out, and she has no matches, so she has come to ask for a light. Rodolfo invites her in, but she suddenly feels faint, and Rodolfo helps her to a chair and gives her some wine. What follows is a wonderfully operatic ten minutes in which Rodolfo and Mimì go from having never met to being deeply in love. We kick off the first big aria of the opera, Rodolfo’s ‘Che gelida manina’, which Mimì responds to with her fabulous ‘mi chiamano Mimì’.</p>

            <VideoPlayer src="https://www.youtube.com/embed/1tlM9arIzZc?autoplay=1" id="1tlM9arIzZc" title="Ramón Vargas sings 'Che gelida manina' at the Metropolitan Opera"/>

            <p>He relights her candle, and she prepares to leave, but she then realises that she lost her key in the commotion of fainting. They search for it, but in the process, all the candles go out. Rodolfo finds the key but pops it in his pocket as he doesn’t want her to leave. They get very cosy, but Rodolfo’s friends call for him outside, so they head to the Latin Quarter together. The scene culminates in the magnificent duet ‘O soave fanciulla’.</p>

            <VideoPlayer src="https://www.youtube.com/embed/-Dtouh3p0qc?autoplay=1" id="-Dtouh3p0qc" title="Nicole Car and Michael Fabiano sing ‘O soave fanciulla’ at the Royal Opera"/>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-104"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act II - Running Time: 20 mins</SynopsisAct>

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/act_2_met.jpeg" alt="The Act II Latin Quarter"/>
              <figcaption>A magnificently excessive Act II set designed by Franco Zeffirelli &copy; Ken Howard/Metropolitan Opera</figcaption>
            </ImageFull>

            <p>The action continues immediately in the Latin Quarter, where the friends arrive for dinner. They first go shopping in the market, with Rodolfo buying Mimì a bonnet and Colline buying himself a winter coat. There’s an extensive children’s chorus and some light mayhem with the arrival of Parpignol, a toy seller.</p>

            <p>They go to the Cafe Momus where they dine, but their pleasant evening is soon interrupted by the arrival of Musetta, Marcello’s on-again-off-again girlfriend, with her rich admirer Alcindoro. She is evidently bored of him and, in an act of extreme attention-seeking, sings perhaps the most famous aria of the whole evening, 'Quando me'n vo'’, better known as Musetta’s waltz.</p>

            <VideoPlayer src="https://www.youtube.com/embed/4RCATl02Pr4?start=11&autoplay=1" id="4RCATl02Pr4" title="Dame Kiri Te Kanawa sings Musetta's walz"/>

            <p>To get rid of Alcindoro, Musetta feigns excruciating pain in her foot from a tight shoe, sending him off to get it mended. He departs in a hurry, and she quickly falls into Marcello’s arms. The bill arrives, and realising they lack the money to pay for it all, Musetta cheekily has it added to Alcindoro’s. They flee, and Alcindoro arrives to shock that Musetta has left and his bill is enormous!</p>
 
          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-105"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act III - Running Time: 30 mins</SynopsisAct>

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/ActIII_Sarasota.jpg" alt="Act III design by David P. Gordan at Sarasota Opera"/>
              <figcaption>Act III, designed by David P. Gordan &copy; Sarasota Opera</figcaption>
            </ImageFull>

            <p>A couple of months have passed, but we are still in the depths of winter. After a brief scene-setting, Mimì arrives, coughing and appearing in generally terrible shape, looking for Marcello. She has learned he now lives at this tavern painting signs for the innkeeper. He emerges; she explains Rodolfo has left her, having acted horribly jealous. Marcello worries about her health and tells her that Rodolfo is asleep inside. He soon emerges, and Mimì hides.</p>

            <p>He’s come looking for Marcello and, unaware that Mimì is listening, initially explains that he left her because of her flirting. He soon breaks down and reveals that he is so worried about her failing health that he uses jealousy to excuse him to leave. He is poor and hopes that she will find a wealthy suitor who can provide better for her.</p>

            <p>Mimì’s coughing and crying reveal herself, and Rodolfo rushes to who her stunned. In contrast, Marcello hears Musetta laughing inside and hurries in jealously. Mimì tries to say goodbye, but as they remind themselves of the good times, they decide to stay together through the winter. Musetta and Marcello emerge quarrelling, and the curtain falls on this quartet of troubled lovers.</p>

            <VideoPlayer src="https://www.youtube.com/embed/3HeeHcFtdAs?start=3&autoplay=1" id="3HeeHcFtdAs" title="Pavarotti and Freni close out Act III in Turin 1996"/>            

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-106"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <SynopsisAct>Act IV - Running Time: 30 mins</SynopsisAct>

            <p>The next winter has come, and we are back in the garret. Rodolfo and Marcello are both single and nostalgic, Musetta and Mimì having left them for wealthy lovers. Schaunard and Colline return home with a small dinner, and they stage a lively, festive banquet despite their situation.</p>

            <p>One of the great operatic chords lands, and the jovial atmosphere instantly cools with the arrival of Musetta. She reveals that Mimì has left her patron, and she found her near death in the street.</p>

            <p>Mimì is brought in, looking deathly ill, and placed on a bed. Musetta and Marcello leave to sell Musetta’s earrings to get enough money for medicine. Colline leaves to pawn his coat, delivering a remarkably poignant aria before he goes (dragging Schaunard out as well to give Mimì and Rodolfo time alone).</p>

            <VideoPlayer src="https://www.youtube.com/embed/ArYceMAJiH8?autoplay=1" id="ArYceMAJiH8" title="James Morris sings 'Vecchia zimarra, senti'"/>            

            <p>Together the pair remember their first meeting and varied happy days. Mimì’s coughing is overwhelming. The friends return with some medicine to soothe the cough and a muff to warm her hands. Mimì thanks them but slips into sleep. They all take this moment in their own ways, but Schaunard approaches Mimì and realises she is dead. The curtain falls as Rodolfo calls out to his lost love, and the survivors all cling together for comfort.</p>

            <VideoPlayer src="https://www.youtube.com/embed/B4EPQlQpTl0?autoplay=1" id="B4EPQlQpTl0" title="The finale, Saltzburg 2012 with Anna Netrebko as Mimì, Piotr Beczala as Rodolfo."/>            

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-107"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Where and When"/>

            <p>La bohème is located fairly specifically because it is based on a book, Scènes de la vie de bohème, by Henri Murger based on his personal experiences of Paris in the 1830s and 40s.</p>

            <p>Acts I and IV take place in the artists ‘garret’. A garret is essentially a loft space but is a particular feature of Parisian architecture from this period. These were up many stairs and cramped with sloping ceilings. Poor insulation and little heating made for deplorable living conditions.</p> 

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/garret.jpg" alt="An example of a Parisian Garret"/>
              <figcaption>Karl Girardet's 1844 Charity in a Garret</figcaption>
            </ImageFull>

            <p>Act II occurs in the Latin Quarter of Paris and specifically within Cafe Momus, a real place that Murger would have frequented. This area of Paris was lively, and this Act tends to be where productions blow the budget on scale.</p>

            <p>Act III takes place by a tavern by the tollhouses of the Barrière d'Enfer just outside Paris (literally Gate of Hell, though by most accounts this, fortunately, doesn’t seem to have been too literal a name!). This is another real-world, historical place where the city would collect taxes on goods entering. However, in the context of bohème, the more significant aspect of this setting is that it’s winter, so freezing, and outside. Expect some snow!</p>

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/barriere-denfer.jpeg" alt="A picture of the Barrière d'Enfer in the 19th Century"/>
              <figcaption>A picture of the Barrière d'Enfer in the 19th Century</figcaption>
            </ImageFull>

            <Header title="History"/>

            <p>Two Italian operas called “La bohème” exist, and they premiered within a year of each other. As you would imagine, this did not come about purely by chance, and there was enormous bad blood as a result. The other is by Ruggero Leoncavallo, and pretty much by all accounts, he got to the story first and even introduced Puccini to it, but his opera premiered second in 1897. This article is about Puccini’s opera, so you can guess which one won in the popularity stakes!</p>

            <ImageRight maxwidth="240px">
              <StaticImage src="../../images/operas/boheme/ruggiero_leoncavallo.jpg" alt="Ruggiero Leoncavallo"/>
              <figcaption>Ruggiero Leoncavallo</figcaption>
            </ImageRight>

            <p>Leoncavallo is mainly known today as the composer of his 1892 opera Pagliacci. He was also a successful librettist and was well known to Puccini, having contributed to the libretto for Puccini’s previous opera and first smash hit “Manon Lescaut”. The story goes that Leoncavallo introduced Puccini to Murger’s book and even offered him a libretto, but Puccini passed on it. Puccini, however, still took up the story. Leoncavallo, who had since decided to set his libretto himself, was shocked to find himself facing a competing opera from the person he’d initially introduced the material to! The competition was only stirred further by the fact the two composers were published by the two biggest rival publishers Ricordi and Sonzogno.</p>

            <p>To add further intrigue to the libretti, both conflate two characters from Murger’s book into one (Francine becoming Mimì). This similarity is most likely not from copying each other but because, in reality, though nominally based on the book, both drew heavily from the play adaptation of 1849 by Théodore Barrière, which first combined these characters. However, the librettists were loath to admit this as Murger’s book was out of copyright, but the play was not!</p>

            <p>In any case, Puccini’s opera premiered in 1896 in Turin under the baton of Arturo Toscanini to somewhat negative critical response. The prominent music critic Carlo Bersezio wrote in La Stampa ‘La Bohème, even as it leaves little impression on the audience’s minds, will leave no great trace upon the history of our lyric theatre’. He would be entirely wrong. The opera was wildly popular with audiences, and by the end of the 19th Century, four years later had been heard just about everywhere in the operatic world.</p>

            <p>Leoncavallo’s opera premiered second in 1897. This article is about Puccini’s opera, so you can guess which one won in the popularity stakes! They would remain rivals for the rest of their careers though Puccini would attend Leoncavallo’s funeral in 1919.</p>

          </MainBodySection>

          <div id="ezoic-pub-ad-placeholder-108"> </div>

            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>

            <Header title="Fun Facts"/>

            <FunFactsHeader>Bohemians</FunFactsHeader>

            <ImageFull>
              <StaticImage src="../../images/operas/boheme/murger_bohemianlife.jpg" alt="An image from Murger's book"/>
              <figcaption>Bohemians as depicted in Murger's book</figcaption>
            </ImageFull>

            <p>So what’s a ‘Bohemian anyway’? There are a variety of meanings to this word some more relevant in the opera’s context than others. Geographically it’s an area of what is now the western Czech Republic. It was used in France as a name for the roving Romani people, better known as Gypsies, as it was wrongly believed they came from the Bohemian region. That connection to living freely, unconventionally, and outside societal norms came to associate the population of aspiring poor artists with the same word. These are Murger’s and then Puccini’s Bohemians.</p>

            <FunFactsHeader>What’s in a cast?</FunFactsHeader>

            <p>With the original production a critical flop, it was important that the productions that quickly followed after be given critic-proof casts. There was to be an early production in Livorno, a town close to Puccini’s birthplace of Lucca, in the summer of 1897. The casting was considered first-rate, but a young, up-and-coming singer was to be Rodolfo, and Puccini had concerns. The solution was to have him sing ‘Che gelida manina’, which hid so beautifully that Puccini said, “Who sent you to me -- God?” This young tenor was Enrico Caruso, who became one of the greatest tenors of his or any age.</p>

            <FunFactsHeader>Extra Scenes</FunFactsHeader>

            <p>Puccini’s librettist team operated with Illica creating a prose scenario and then Giacosa setting the scene to verse. In the case of Bohème, they were working with Murger’s book, which is more episodic and had too many characters than the straight-line narrative required of an operatic libretto. In some cases, what they took was quite literal; Act IV is very similar to Murger’s Chapter 18, with the significant caveat that in the book, it is Francine who dies rather than Mimì. Though don’t worry, Mimì still dies by Chapter 22... Their original plan had extra scenes, including one involving Musetta and a wealthy Viscount. However, despite cutting the scene, they failed to remove a reference to it in the eventual Act III. It passes without much notice when staged, but it doesn’t make much sense if you’re reading the libretto!</p>

          </MainBodySection>

            <div id="ezoic-pub-ad-placeholder-109"> </div>
  
            <AdContainer>
              <AdBanner
                style={{ display: 'block' }}
                slot="3461570696"
                format="auto"
                responsive="true"
              />
            </AdContainer>

          <MainBodySection>            

            <FunFactsHeader>Modern Adaptations</FunFactsHeader>

            <p>Bohème has caught the modern imagination several times but most prominently in two enormously successful contemporary works. The first was Rent by Jonathan Larson, an astonishing rock musical that shifts the action to New York City around 1990 with TB replaced by HIV/AIDS. It’s not a direct adaptation by any means but the characters (e.g. Mimì -> Mimi, Rodolfo -> Roger) and major events largely parallel the opera with some nifty borrowing of melodies for a few of the songs.</p>
            
            <ImageFull>
              <StaticImage src="../../images/operas/boheme/moulin_boheme.jpeg" alt="Baz Luhrmann's La Boheme"/>
              <figcaption>Baz Luhrmann's La Boheme &copy; Mike Kepka/The Chronicle</figcaption>
            </ImageFull>

            <p>The second is Baz Luhrmann’s massive Moulin Rouge! A riotous movie (and now stage musical) that lifts from dozens of different sources but whose narrative bears some resemblance to Bohème with a dash of La Traviata. Luhrmann had previously directed an aesthetically similar Bohème at the Sydney Opera House and would go on after Moulin Rouge! came out to remount that production on Broadway (a scarce example of commercial opera with multiple casts to achieve the full weekly schedule).</p>

            <Header title="Bibliography"/>

            <Bibliography content={bibliography}/>

        </MainBodySection>
    </Layout>
  )
}

export default BohemePage
